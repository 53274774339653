@tailwind base;
@tailwind components;
@tailwind utilities;

@layer components {
  .sidebar-icon {
    @apply relative flex items-center justify-center text-gray-200;
  }
}

body {
  background-color: #121212;
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

/*Chrome*/
@media screen and (-webkit-min-device-pixel-ratio:0) {
  input[type='range'] {
    overflow: hidden;

    -webkit-appearance: none;
    background-color: transparent;
  }
  
  input[type='range']::-webkit-slider-runnable-track {
    height: 10px;
    -webkit-appearance: none;
    color: rgb(34 197 94);
    margin-top: -1px;
  }
  
  input[type='range']::-webkit-slider-thumb {
    width: 10px;
    -webkit-appearance: none;
    height: 10px;
    cursor: ew-resize;
    box-shadow: -80px 0 0 80px rgb(34 197 94);
  }

}
/** FF*/
input[type="range"]::-moz-range-progress {
background-color: rgb(34 197 94); 
}
input[type="range"]::-moz-range-track {  
background-color: #121212;
}
/* IE*/
input[type="range"]::-ms-fill-lower {
background-color: rgb(34 197 94); 
}
input[type="range"]::-ms-fill-upper {  
background-color: #121212;
}
